<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.systemNotification"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        prop="expire_date"
                        :label="$t('message.expire_date')"
                      > 
                        <el-date-picker
                          v-model="form.expire_date"
                          clearable 
                          type="datetime"
                          :placeholder="$t('message.expire_date')"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                      </el-form-item> 
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="24">
                      <el-form-item
                        prop="comment"
                        :label="$t('message.text')"
                      >
                        <el-input
                          type="textarea"
                          :placeholder="$t('message.text')"
                          :autosize="{ minRows: 2}"
                          v-model="form.comment">
                        </el-input>
                      </el-form-item> 
                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  
  mixins: [form, drawer],
  data() {
    return {

    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "systemNotifications/rules",
          model: "systemNotifications/model",
          columns: "systemNotifications/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "systemNotifications/store",
      }),
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
